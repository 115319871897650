<template>
  <div class="elplore-container">
    <div class="order-menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo row-center"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="2">
          艺术家
          <img
            v-if="activeIndex == 2"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item
          index="1"
          class="el-menu-item1"
        >
          馆藏
          <img
            v-if="activeIndex == 1"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>

      </el-menu>
    </div>
    <!-- 占位 -->
    <div style="width:100vw;height:116px">
    </div>
    <!-- 馆藏 -->
    <van-list
      class="explore-collection"
      v-if="activeIndex == 1"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="load"
    >
      <div
        v-for="(item,index) in collectionList"
        :key="index"
        :style="'position: relative;border-radius: 8px;margin-left:5vw;margin-bottom: 20px;width: 90vw;height: 77vw;background-image: url('+ item.bg +');background-repeat: no-repeat;background-position: 0 0;background-size: cover;background-color: transparent;'"
      >
        <div
          @click="goCollectionDetails(item)"
          class="explorer-collection-mask"
        >
        </div>
        <!-- <p
          @click="goCollectionDetails(item)"
          class="explore-collection-item-title"
        >{{ item.collectionName }}</p> -->
        <div
          class="explore-collection-item-content"
          v-if="item.itemImgList.length"
        >
          <div class="explore-collection-item-list">
            <img
              v-for="(imgItem,index) in item.itemImgList"
              :key="index"
              :src="imgItem.image"
              @click="goItemDetails(imgItem)"
            >
          </div>

        </div>
      </div>
    </van-list>
    <!-- 艺术家 -->
    <van-list
      class="explore-artist"
      v-if="activeIndex == 2"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="load"
    >
      <div
        class="explore-artist-item"
        v-for="(item,index) in artistList"
        :key="index"
      >
        <!-- 艺术家名称 -->
        <div class="explore-artist-icon-name row-between">
          <div class="row-center">
            <img
              class="explore-artist-icon"
              :src="item.avatar"
            />
            <p class="explore-artist-name">{{ item.realName }}</p>
          </div>
          <a
            @click="goArtistDetails(item)"
            class="explore-artist-more"
          >查看更多</a>
        </div>
        <div
          class="explore-artist-item-content row-center"
          v-if="item.image.length"
        >
          <img
            v-for="(imgItem,index) in item.image"
            :key="index"
            :src="imgItem.image"
            @click="goItemDetails(imgItem)"
          >
        </div>
      </div>
    </van-list>
    <el-dialog
      title="账户未登录"
      :visible.sync="loginVisible"
      @confirm="$router.push('/login')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="loginVisible = false">取 消</el-button>
        <div
          @click="$router.push('/login')"
          class="confirm-button row-center"
        >去登录</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import Vue from 'vue'
import Cookies from 'js-cookie'
import { Menu,MenuItem,Button,Dialog } from 'element-ui';
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Button)
Vue.use(Dialog)
import { List,Popup } from 'vant';
Vue.use(List);
Vue.use(Popup);
import { mapGetters } from "vuex";

export default {
  data () {
    return {
      activeIndex: '1',
      loginVisible: false,
      loading: false,
      finished: false,
      goodsParam: {
        page: 0,
        limit: 12,
      },
      collectionList: [
      ],
      artistList: [
      ]
    }
  },
  computed: {
    ...mapGetters(["isSetPwd","headerShowBg"]),
  },
  mounted () {
    this.activeIndex = '2'
    // window.addEventListener('scroll',this.handleScroll)
    if (!Cookies.get('Login')) {
      this.loginVisible = true
    } else {
      this.$store.commit('SHOW_APPLOADING')
      if (localStorage.getItem('exploreIndex')) {
        this.activeIndex = localStorage.getItem('exploreIndex')
      } else {
        localStorage.setItem('exploreIndex',this.activeIndex)
      }
    }
  },
  methods: {
    handleScroll () { //改变元素#searchBar的top值
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop <= 62) {
        this.$store.commit('HIDE_HEADER')
      } else {
        this.$store.commit('SHOW_HEADER')
      }
    },
    // 下拉加载
    load () {
      this.loading = true
      this.goodsParam.page += 1
      if (Cookies.get('Login')) {
        this.getExplore()
      }
    },
    // 馆藏详情
    goCollectionDetails (item) {
      this.$router.push('/management?id=' + item.id)
    },
    // 艺术家详情
    goArtistDetails (item) {
      this.$router.push('/artistDetails?uid=' + item.uid)
    },
    // 分页获取馆藏和艺术家
    getExplore () {
      if (this.activeIndex == 1) {
        api
          .get('collect/library/list?page=' + this.goodsParam.page + '&limit=' + this.goodsParam.limit)
          .then(result => {
            if (result.data.success) {
              result.data.data.map(i => {
                this.collectionList.push({
                  bg: i.image,
                  id: i.id,
                  collectionName: i.name,
                  itemImgList: i.productVoList
                })
              })
              // 艺术家逻辑处理
              if (result.data.data.length < this.goodsParam.limit) {
                this.finished = true
              } else {
                this.finished = false
              }
            } else {
              this.finished = true
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
          })

        this.loading = false
      } else {
        // 下拉加载更多艺术家接口对接
        api
          .get('artist/list?page=' + this.goodsParam.page + '&size=' + this.goodsParam.limit)
          .then(result => {
            if (result.data.success) {
              // 艺术家逻辑处理
              result.data.data.content.map(i => {
                this.artistList.push({
                  avatar: i.avatar,
                  realName: i.realName,
                  uid: i.uid,
                  image: i.producerProduct
                })
              })
              // 艺术家逻辑处理
              if (result.data.data.content.length < this.goodsParam.limit) {
                this.finished = true
              } else {
                this.finished = false
              }
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
          })
        this.loading = false
      }
      this.$store.commit('HIDE_APPLOADING')
    },
    // tab切换
    handleSelect (key) {
      this.activeIndex = key
      localStorage.setItem('exploreIndex',this.activeIndex)
      this.goodsParam.page = 0
      this.collectionList = []
      this.artistList = []
      this.finished = false
      this.load()
    },
    goItemDetails (item) {
      this.$router.push('/goodDetails?id=' + item.id + '&isBanner=buy')
    },
  },
  // 去除监听事件
  beforeDestroy () {
    window.removeEventListener('scroll',this.handleScroll)
    this.$store.commit('HIDE_HEADER')
  },
}
</script>
<style lang="less" scoped>
.elplore-container {
  width: 100vw;
  height: auto;
  min-height: calc(100vh - 50px);
  background-image: url('../assets/icon/explore/explore-bg.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  background-color: transparent;
  .order-menu {
    background: transparent !important;
    .el-menu {
      background: transparent !important;
      background-color: transparent !important;
    }
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background: transparent !important;
      background-color: transparent !important;
    }
    .el-menu-item {
      font-size: 1.125rem;
    }
    .el-menu-item {
      margin-right: 20px;
    }
  }
  .explore-artist {
    width: 100vw;
    height: calc(100vh - 166px);
    overflow-y: auto;
    .explore-artist-item {
      width: 94vw;
      margin-left: 3%;
      height: calc(18vw + 82px);
      background: #2b2c2d;
      border-radius: 8px;
      margin-bottom: 12px;
      .explore-artist-icon-name {
        width: 94%;
        margin-left: 3%;
        padding-top: 5px;
        height: 50px;
        .explore-artist-icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 14px;
        }
        .explore-artist-name {
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          line-height: 16px;
        }
        .explore-artist-more {
          font-size: 12px;
          font-weight: 500;
          color: #f3cdb6;
          line-height: 17px;
          min-width: 48px;
        }
      }
      .explore-artist-item-content {
        width: 90%;
        margin-left: 5%;
        height: calc(18vw + 32px);
        z-index: 101;
        background: transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        img {
          width: 18vw;
          height: 18vw;
          border-radius: 4px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .explore-collection {
    width: 100vw;
    height: calc(100vh - 166px);
    overflow: auto;
    .explorer-collection-mask {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      position: absolute;
      top: 0;
      left: 0;
    }
    .explore-collection-item-title {
      position: absolute;
      left: 40px;
      bottom: 140px;
      font-size: 30px;
      font-family: FZYDCHJW;
      font-weight: normal;
      color: #ffffff;
      line-height: 35px;
      letter-spacing: 3px;
    }
    .explore-collection-item-content {
      width: 100%;
      height: 100px;
      position: absolute;
      background: rgba(17, 21, 30, 0.61);
      border-radius: 0px 0px 8px 8px;
      backdrop-filter: blur(7px);
      bottom: 0;
      z-index: 101;

      .explore-collection-item-list {
        width: 90%;
        margin-left: 5%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
      img {
        width: 18vw;
        height: 18vw;
      }
    }
  }
}
</style>